<template>
  <div class="">
    <div class="row page-title">
      <label>AMEND MEDIA VENDOR REQUEST</label>
    </div>
    <div class="row stepper-outer">
      <div class="col-lg-10 mx-auto chevron-steps-container">
        <chevron-steps :steps="tabs" @click="navigate"></chevron-steps>
      </div>
    </div>
    <div class="pt-30 row">
      <div class="col-lg-12">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
const VendorInfo1 = () => import("../components/Vendor/MediaAmend/EmployeeInfo");
const VendorInfo2 = () => import("../components/Vendor/MediaAmend/SetupInfo");
const VendorInfo3 = () => import("../components/Vendor/MediaAmend/CreditCardInfo");
const VendorInfo4 = () => import("../components/Vendor/MediaAmend/EmployeeRelationship");
const VendorInfo5 = () => import("../components/Vendor/MediaAmend/Review");

export default {
  mounted() {
      let id = this.id;
    if (id) {
      if (this.isDraft == true) {
        this.$store.dispatch("vendormediaamend/getDraftData", parseInt(id));
      } else {
        this.$store.dispatch("vendormediaamend/GetFormData", parseInt(id))
          .catch((err) => {
            if (err.response && err.response.status == 403) {
              this.$router.push({
                name: "error",
                params: {
                  message: "UnAuthorized"
                },
              });
            }
            else {
              this.Error = true;
              alert(err);
            }
          });
      }
    }
    this.$store.commit("client/reset");
    window.localStorage.setItem("UploadfileId", "");
    this.$emit("can-continue", { value: true });
    this.$on("stepChanged", (tabIndex) => {
      this.UpdateSteps(tabIndex);
    });
    this.$on("previousStep", (tabIndex) => {
      this.UpdatePreviousSteps(tabIndex);
    });
    this.$on("stepInitiated", (tabIndex) => {
      this.InProgressStep(tabIndex);
    });
    // if (window.performance.navigation.type === 1) {
    //   this.$router.push({ name: 'Media Vendor Amend Employee Info' });
    // }
  },
  methods: {
    UpdateSteps: function (index) {
      for (var i = 0; i < this.tabs.length; i++) {
        if (i <= index || index == this.tabs.length - 1) {
          this.$set(this.tabs[i], "state", "complete");
          this.completedStep = i;
        }
      }
    },
    UpdatePreviousSteps: function (index) {
      if (index > 0) {
        if (index == 5 && this.tabs[index].state != "in progress") {
          this.$set(this.tabs[index], "state", "in progress");
        }
        else {
          this.$set(this.tabs[index - 1], "state", "in progress");
        }

      }
    },
    InProgressStep: function (index) {
      for (var i = 0; i < this.tabs.length; i++) {
        if (i == index) {
          this.$set(this.tabs[i], "state", "in progress");
        } else if (i <= this.completedStep) {
          this.$set(this.tabs[i], "state", "complete");
        } else if (this.tabs[i].state != "complete") {
          this.$set(this.tabs[i], "state", "");
        }
      }
      if (this.completedStep < 0 && index > 0) {
        if (this.id) {
          this.$router.push({
            path: `${this.tabs[0].route}/${parseInt(this.id)}`,
          });
        } else {
          this.$router.push({
            path: this.tabs[0].route,
          });
        }
      }
    },
    enableNext: function () {
      this.$emit("can-continue", { value: true });
    },
    navigate(index) {
      let currentRouteIndex = this.$route.meta.tabIndex;
      if (index > this.completedStep + 1 && index > currentRouteIndex) {
        //this.$store.dispatch("progress/setErrorMessage","Please complete previous steps to proceed!");
        // this.$root.toastDanger("check color");
      } else {
        if (this.id) {
          this.$router.push(`${this.tabs[index].route}/${this.id}`);
        } else {
          this.$router.push(this.tabs[index].route);
        }
      }
    },
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    isDraft: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    this.$store.commit("vendormediaamend/reset");
  },

  data() {
    return {
      completedStep: -1,
      tabs: [
        {
          icon: "1",
          name: "1",
          description: "Employee/Request Type Info",
          component: VendorInfo1,
          route: "/Request/Vendor/Media/Amend/EmployeeInfo",
          state: "in progress",
        },
        {
          icon: "2",
          name: "2",
          description: "SETUP INFO",
          route: "/Request/Vendor/Media/Amend/SetupInfo",
          component: VendorInfo2,
          state: this.id && this.isDraft != true ? "complete" : "",
        },
        {
          icon: "3",
          name: "3",
          description: "CREDIT CARD/BANKING INFO",
          route: "/Request/Vendor/Media/Amend/CreditCardInfo",
          component: VendorInfo3,
          state: this.id && this.isDraft != true ? "complete" : "",
        },
        {
          icon: "4",
          name: "4",
          description: "EMPLOYEE RELATIONSHIP",
          route: "/Request/Vendor/Media/Amend/EmployeeRelationship",
          component: VendorInfo4,
          state: this.id && this.isDraft != true ? "complete" : "",
        },
        {
          icon: "5",
          name: "5",
          description: "REVIEW",
          route: "/Request/Vendor/Media/Amend/Review",
          component: VendorInfo5,
          state: "",
        }
      ],
    };
  },
  watch: {
  },
  computed: {
    IsEdit() {
        return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
    },
  },
};
</script>

<style scoped>
.stepper-box,
.stepper-box .top .steps-wrapper .step .circle {
  background-color: transparent !important;
}

.content-holder {
  background-color: #ffffff !important;
}

.row.page-title {
  height: 55px;
  background-color: #eef3f5 !important;
}

.row.page-title>label {
  padding: 14px 0px 14px 55px !important;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.81px;
  color: #4d5161;
  text-transform: none !important;
}


.progress-bar[data-v-ef603d5c]:not(.hide-number):before {
  width: 30px !important;
  height: 30px !important;
  padding-top: 6px !important;
}
</style>
